import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import {
  Breadcrumbs,
  Button,
  Icon,
  IconButton,
  InfoCard,
  InputField,
  Link,
  styled,
  Submit,
  Tooltip,
  WarningCard,
} from "@ioxio-priv/dataspace-ui"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { ReactComponent as DefinitionIcon } from "@/assets/definition-icon.svg"
import { ReactComponent as DSIIcon } from "@/assets/dsi-icon.svg"
import { ReactComponent as SourceIcon } from "@/assets/source-icon.svg"
import { MailLink, MainContentBox } from "@/commonStyles"
import Header from "@/components/Header"
import { MetaData } from "@/components/MetaData"
import SecretInput from "@/components/SecretInput"
import { BreadCrumbsPath } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes.json"
import FullPageSpinner from "@/containers/FullPageSpinner"
import { Icons } from "@/dsIcon"
import useGetDefinitions from "@/hooks/useGetDefinitions"
import ApiTokenAPI from "@/services/apiTokenAPI"
import { config } from "@/settings"
import {
  API_TOKEN_VALIDITY_TIME,
  copyToClipboard,
  getQueryParams,
  parseDSI,
} from "@/utilities"
import { swaggerUIUrl } from "@/utilities/urls"

export default function ViewAccessControlKeys({ history }) {
  const { group } = useParams()
  const { dsi } = getQueryParams(["dsi"])
  const { source, definition } = parseDSI(dsi)

  const [keys, setKeys] = useState([])
  const [canRequestApiTokens, setCanRequestApiTokens] = useState(false)
  const [loading, setLoading] = useState(true)
  const [apiToken, setApiToken] = useState("")
  const [_showCurlExample, setShowCurlExample] = useState(false)
  const [_showFetchToken, setShowFetchToken] = useState(false)

  const { definitions, loading: loadingDefinitions } = useGetDefinitions()

  useEffect(() => {
    ;(async () => {
      if (!source || !definition) {
        await toast.error("Wrong URL")
        return await history.push({ pathname: ROUTES.ACCESS_CONTROL_KEYS })
      }
      const { ok, data, status } = await ApiTokenAPI.listAccessControlKeys(dsi, group)
      if (ok) {
        setKeys(data.accessControlKeys)
        setCanRequestApiTokens(data.canRequestApiTokens)
      } else {
        if (status === 403) {
          await toast.error("Insufficient permissions")
          return await history.push({ pathname: ROUTES.ACCESS_CONTROL_KEYS })
        } else {
          await toast.error("Can't open access control keys")
          return history.push({ pathname: ROUTES.ACCESS_CONTROL_KEYS })
        }
      }
      setLoading(false)
    })()
  }, [history])

  function showFetchToken() {
    setShowFetchToken(true)
    setShowCurlExample(false)
  }

  function showCurlExample() {
    setShowCurlExample(true)
    setShowFetchToken(false)
  }

  async function refreshKey(key) {
    const params = { group, dsi, accessControlKey: key }
    const { ok, data, error } = await ApiTokenAPI.refreshAccessControlKey(params)
    if (ok) {
      setKeys(data.accessControlKeys)
      await toast.success("New access control key has been generated")
    } else {
      await toast.error(error)
    }
    return { ok }
  }

  async function fetchApiToken() {
    const params = { group, dsi, accessControlKey: keys[0] }
    const { ok, data } = await ApiTokenAPI.fetchApiToken(params)
    if (ok) {
      showFetchToken()
      setApiToken(data.apiToken)
    } else {
      toast.error("Failed to fetch an API key")
    }
    return { ok }
  }

  if (loading || loadingDefinitions) {
    return <FullPageSpinner />
  }

  const iconSize = "1.75rem"
  const iconProps = { width: iconSize, height: iconSize }

  return (
    <MainContentBox>
      <MetaData {...labels.meta.accessControlKeys} />
      <Grid container columnSpacing={"1.5rem"}>
        <Grid item xs={12} lg={8}>
          <Header
            breadCrumb={
              <Breadcrumbs
                current={"View"}
                paths={[
                  BreadCrumbsPath.DEVELOPER_PORTAL,
                  BreadCrumbsPath.ACCESS_CONTROL_KEYS,
                ]}
              />
            }
            title={"View access control keys"}
          >
            <Info>
              <Typography variant={"h4"}>{definitions[definition].summary}</Typography>
              <Row title={"Group"}>
                <IconWrapper>
                  <Icon name={"admin"} variant={"outlined"} />
                </IconWrapper>
                <span>{group}</span>
              </Row>
              <Row title={"Data product definition"}>
                <IconWrapper>
                  <DefinitionIcon {...iconProps} />
                </IconWrapper>
                <span>{definition}</span>
              </Row>
              <Row title={"Source"}>
                <IconWrapper>
                  <SourceIcon {...iconProps} />
                </IconWrapper>
                <span>{source}</span>
              </Row>
              <Row title={"Data source identifier"}>
                <IconWrapper>
                  <DSIIcon {...iconProps} />
                </IconWrapper>
                {dsi}
              </Row>
            </Info>
          </Header>
          <div>
            <p>
              Access control keys for generating API tokens to access this data source
              as the group <b>{group}</b>. The tokens are signed using{" "}
              <Link target={"_blank"} rel={"noreferrer"} href={config.jwksUrl}>
                the Dataspace's JWKS
              </Link>
              .
            </p>
          </div>
          <Keys>
            <KeysHeader variant={"h4"}>
              <span>Access control keys</span>
              <Tooltip>
                You can use these keys to generate API tokens via the Product Gateway
                APIs to access this data source
              </Tooltip>
            </KeysHeader>
            {keys.map((key) => (
              <SecretInput
                key={key}
                secret={key}
                asyncOnRefresh={refreshKey}
                name={"Access control key"}
                modalDescription={
                  <>
                    If you refresh this access control key it will no longer be valid
                    for your application, please ensure you have set up your software to
                    use the alternate key before refreshing this one.
                  </>
                }
              />
            ))}
          </Keys>

          {config.apiTokensRequirePayment || !canRequestApiTokens ? (
            <div data-testid="api-token-warning">
              <WarningCard>
                <Typography>
                  Your group is not set up to use API tokens and you will be unable to
                  request them, please contact{" "}
                  <MailLink href={`mailto:${config.supportEmail}`}>
                    {config.supportEmail}
                  </MailLink>
                </Typography>
              </WarningCard>
            </div>
          ) : (
            <Helper>
              <div>
                For testing purposes you can generate an API token using the button
                below, alternatively you can view an example cURL request. The token
                will allow you to access the data product by adding it to the{" "}
                <b>
                  <i>X-API-Key</i>
                </b>{" "}
                header to the request for the data product from the Product Gateway.
              </div>
              <Buttons>
                <Submit
                  icon={"api"}
                  asyncOnClick={{
                    asyncFn: fetchApiToken,
                    loadingText: "Loading...",
                  }}
                >
                  Generate API token
                </Submit>
                <Button icon={"link"} onClick={showCurlExample}>
                  Show cURL example
                </Button>
              </Buttons>

              {_showFetchToken && apiToken.length > 0 && (
                <div>
                  <ApiToken>
                    <InputField
                      value={apiToken}
                      label={"X-API-Key"}
                      name={"apiToken"}
                      description={
                        <p>
                          You can make a test request on our{" "}
                          <Link
                            target={"_blank"}
                            rel={"noreferrer"}
                            href={swaggerUIUrl(definition)}
                          >
                            Swagger UI
                          </Link>
                          . Enter <b>{source}</b> as the source in the request and fill
                          in the <b>X-API-Key</b>.
                        </p>
                      }
                      readonly
                    >
                      <IconButton
                        icon={Icons.copy}
                        title={"Copy"}
                        color={"secondary"}
                        variant={"outlined"}
                        onClick={() =>
                          copyToClipboard(
                            apiToken,
                            "API token has been copied to the clipboard"
                          )
                        }
                      />
                    </InputField>
                  </ApiToken>
                  <InfoCard>The token is valid for {API_TOKEN_VALIDITY_TIME}.</InfoCard>
                </div>
              )}
              {_showCurlExample && (
                <CodeExampleWrapper>
                  <p>Example cURL request to generate an API token:</p>
                  <Code>
                    curl -X POST '{config.productGateway}/api/v1/api-token/request' -H
                    'Content-Type: application/json' --data-raw '{"{"}"sub": "{group}",
                    "aud": "{dsi}", "accessControlKey": "{keys[0]}"{"}"}'
                  </Code>
                </CodeExampleWrapper>
              )}
            </Helper>
          )}
        </Grid>
      </Grid>
    </MainContentBox>
  )
}

const Keys = styled("div")`
  display: flex;
  gap: 0.5rem;
  margin: 1.5rem 0;
  border-radius: 0.3125rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: ${(p) => p.theme.palette.secondary.light};
  padding: 1rem;
`

const ApiToken = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1.5rem;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const Helper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  button {
    align-self: flex-start;
  }
`

const KeysHeader = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Info = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: ${(p) => p.theme.palette.secondary.light};
  border-radius: 0.3125rem;
  padding: 1rem;
`

const Row = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const IconWrapper = styled("div")`
  width: 1.75rem;
  display: grid;
  align-self: center;
`

const Buttons = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0 1rem 0;
`

const CodeExampleWrapper = styled("div")`
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  border-radius: 5px;
  padding: 1rem;

  p {
    font-weight: 500;
  }
`

const Code = styled("code")`
  margin-top: 0.5rem;
  font-size: 0.8rem;
  display: block;
  padding: 0.75rem;
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  border-radius: 5px;
  background: ${(p) => p.theme.palette.primary.light};
`
