import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

export function MetaData({ title }) {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Developer portal">
        <meta charSet="utf-8" />
        <meta property="og:image" content={import.meta.env.VITE_OG_IMAGE_URL} />
        <title>{title}</title>
      </Helmet>
    </HelmetProvider>
  )
}
