import { styled } from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"

export const MainContentBox = styled(Box)`
  width: 100%;
  margin: 0 auto;
  overflow-wrap: break-word;
  word-break: break-word;
`

export const MailLink = styled("a")`
  color: ${(p) => p.theme.palette.secondary.main};
  line-height: 150%;
  border-radius: 0.3125rem;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.palette.success.light};
  }

  &:focus {
    outline: 2px solid ${(p) => p.theme.palette.success.light};
    outline-offset: "2px";
    border-radius: "0.3125rem";
  }
`

export const FormBody = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`

export const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const HorizontalLine = styled("hr")`
  border-color: ${(p) => p.theme.palette.neutral.main};
  border-top: none;
`
