import React from "react"

import { IconLink, LinkButton, styled } from "@ioxio-priv/dataspace-ui"
import { Grid, useMediaQuery, useTheme } from "@mui/material"

import Text from "@/components/Text"
import { Icons } from "@/dsIcon"

const nameSx = {
  fontWeight: 600,
  wordBreak: "break-word",
}

export default function ApplicationItem({ name, clientId }) {
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <AppGrid container data-testid={`${name}-${clientId}`}>
      <ControlsGrid item sm={8}>
        <Text csx={nameSx}>{name}</Text>
      </ControlsGrid>
      <Grid item>
        {mobileScreen ? (
          <IconLink
            icon={Icons.edit}
            href={`/apps/edit/${name}/${clientId}`}
            color={"primary"}
          />
        ) : (
          <LinkButton
            icon={Icons.edit}
            href={`/apps/edit/${name}/${clientId}`}
            color={"primary"}
          >
            Edit
          </LinkButton>
        )}
      </Grid>
    </AppGrid>
  )
}

const AppGrid = styled(Grid)`
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  justify-content: space-between;
  align-items: start;
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  border-radius: 0.3125rem;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  background: ${(p) => p.theme.palette.secondary.light};
`

const ControlsGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1.5rem;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 60%;
    gap: 0.8rem;
  }
`
