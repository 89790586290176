import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"

import { useSearchUrl } from "@/context/SourcesSearchContext"
import DataSourceAPI from "@/services/dataSourceAPI"

export default function useSearchDefinitions() {
  const { searchUrl } = useSearchUrl()

  const queryParams = new URLSearchParams(searchUrl)
  const queryTags = queryParams.get("tags") || []
  const queryStableVersion = queryParams.get("stableVersion") === "true"

  const [definitions, setDefinitions] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedTags, setSelectedTags] = useState(queryTags)
  const [stableVersion, setStableVersion] = useState(queryStableVersion)
  const requestQueue = useRef(null)
  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    setLoading(true)

    const body = {
      stableVersion: stableVersion,
      tags: selectedTags,
    }

    if (isRequesting) {
      requestQueue.current = body
    } else {
      fetchDefinitions(body)
    }
  }, [selectedTags, stableVersion])

  async function fetchDefinitions(body) {
    setIsRequesting(true)
    const { ok, data, error } = await DataSourceAPI.searchDataSources({
      filter: body,
    })
    if (ok) {
      setDefinitions(data.results)
      setLoading(false)
    } else {
      setDefinitions([])
      toast.error(error)
    }

    if (requestQueue.current) {
      const body = requestQueue.current
      requestQueue.current = null
      fetchDefinitions(body)
    } else {
      setIsRequesting(false)
    }
  }

  return {
    definitions,
    loading,
    selectedTags,
    setSelectedTags,
    stableVersion,
    setStableVersion,
  }
}
