import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import DataSourceAPI from "../services/dataSourceAPI"

export default function useDataSourceErrors(definition, source) {
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const { ok, data, error } = await DataSourceAPI.listErrors(definition, source)
      if (ok) {
        setErrors(data.errors.reverse())
        setLoading(false)
      } else {
        setErrors([])
        toast.error(error)
      }
    })()
  }, [definition, source])
  return { errors, loading, setErrors }
}
