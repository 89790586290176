import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import {
  IconLink,
  InputField,
  Link,
  LinkButton,
  Select,
  Submit,
} from "@ioxio-priv/dataspace-ui"
import { useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

import { FormBody, HorizontalLine } from "@/commonStyles"
import AccessControlMode from "@/components/AccessControlMode"
import { SCHEMA } from "@/components/CreateDataSourceForm/validation"
import Form from "@/components/Form"
import FormInput from "@/components/FormInput"
import FormRadioGroup from "@/components/FormRadioGroup"
import FormSelect from "@/components/FormSelect"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import InitialLoading from "@/containers/InitialLoading"
import { Icons } from "@/dsIcon"
import useMyGroups from "@/hooks/useMyGroups"
import dataSourceAPI from "@/services/dataSourceAPI"
import { config } from "@/settings"
import { toastError } from "@/utilities/errors"

export default function CreateDataSourceForm({
  asyncOnSubmit = async () => ({ ok: true }),
}) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [group, setGroup] = useState()
  const [variant, setVariant] = useState()
  const { myGroups, loading: loadingMyGroups } = useMyGroups()
  const [definitionOptions, setDefinitionOptions] = useState([])
  const [loadingDefinitions, setLoadingDefinitions] = useState(false)

  useEffect(async () => {
    setLoadingDefinitions(true)
    const { data, ok } = await dataSourceAPI.getDataSourceDefinitions()
    if (ok) {
      const definitions = data.definitions.map((df) =>
        df.version ? `${df.definition}_v${df.version}` : df.definition
      )
      setDefinitionOptions(definitions)
      setLoadingDefinitions(false)
    }
  }, [])
  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(SCHEMA),
    defaultValues: {
      state: "private",
      accessControlMode: "none",
    },
  })

  let err = ""
  if (Object.prototype.hasOwnProperty.call(form.formState.errors, "group")) {
    err = form.formState.errors["group"].message
  }

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to create data source", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (!isFormValid) {
      return {
        ok: false,
      }
    }
    const data = form.getValues()

    const payload = {
      source: data.variant ? `${data.group}:${data.variant}` : data.group,
      sourceUrl: data.baseUrl,
      state: data.state,
      accessControlMode: data.accessControlMode,
      definition: data.definition,
    }
    return asyncOnSubmit(payload, handleBackendErrors)
  }

  if (loadingMyGroups || loadingDefinitions) {
    return <InitialLoading />
  }

  return (
    <Wrapper>
      <Form
        onSubmit={onSubmit}
        rightButtons={
          <>
            {smallScreen ? (
              <>
                <IconLink href="/sources" variant={"outlined"} icon={Icons.cancel} />
                <Submit
                  icon={Icons.success}
                  iconOnly
                  label={"Create"}
                  color="success"
                  asyncOnClick={{
                    asyncFn: _asyncOnSubmit,
                  }}
                />
              </>
            ) : (
              <>
                <LinkButton variant={"outlined"} icon={Icons.cancel} href={`/sources`}>
                  Cancel
                </LinkButton>
                <Submit
                  icon={Icons.success}
                  color="success"
                  asyncOnClick={{
                    asyncFn: _asyncOnSubmit,
                  }}
                >
                  Create
                </Submit>
              </>
            )}
          </>
        }
      >
        <FormBody>
          <FormSelect
            baseProps={{
              "data-testid": "definition",
            }}
            label={labels.dataSource.fields.dataProduct.label}
            name={"definition"}
            options={definitionOptions}
            form={form}
            required
            placeholder={"Data Product"}
            tooltipText={labels.dataSource.fields.dataProduct.tooltipText}
          >
            <>
              Which data product is your source implementing? View definitions or
              propose new ones at{" "}
              <Link target="_blank" rel="noreferrer" href={config.sourcesRepo}>
                {config.sourcesRepo}
              </Link>
            </>
          </FormSelect>
          <Controller
            name={"group"}
            control={form.control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                label={labels.dataSource.fields.group.label}
                name={"group"}
                handleChange={setGroup(field.value)}
                baseProps={{ "data-testid": "group", ...field }}
                options={myGroups.map((item) => item.group)}
                addItemButton={
                  <LinkButton href={ROUTES.GROUP_LIST} color={"success"}>
                    + Add a new group
                  </LinkButton>
                }
                error={err}
                value={field.value}
                required={true}
                placeholder={"Select group"}
                tooltipText={labels.dataSource.fields.group.tooltipText}
              />
            )}
          />
        </FormBody>
        <HorizontalLine />
        <FormBody>
          <Controller
            baseProps={{
              autoCapitalize: "none",
              "data-testid": "variant",
            }}
            name={"variant"}
            control={form.control}
            defaultValue={""}
            render={({ field }) => (
              <InputField
                label={"Variant"}
                name={"variant"}
                handleChange={setVariant(field.value)}
                placeholder={labels.dataSource.fields.variant.label}
                tooltipText={labels.dataSource.fields.variant.tooltipText}
                baseProps={{
                  ...field,
                  autoCapitalize: "none",
                  "data-testid": "variant",
                }}
              />
            )}
          />
          <FormInput
            required
            baseProps={{
              autoCapitalize: "none",
              "data-testid": "baseUrl",
            }}
            label={labels.dataSource.fields.baseUrl.label}
            name={"baseUrl"}
            form={form}
            control={form.control}
            placeholder={"https://"}
            tooltipText={labels.dataSource.fields.baseUrl.tooltipText}
          />
          <FormRadioGroup
            form={form}
            name="state"
            baseProps={{
              "data-testid": "state",
            }}
            options={[
              {
                label: "Private",
                description: `Suitable only for your own testing using the X-Preview-Token header with the name <b>${group}:${variant}</b>`,
                value: "private",
              },
              {
                label: "Unlisted",
                description: `Will not be listed on the available data sources listing, but be available for those who know it exists with the name <b>${group}:${variant}</b>`,
                value: "unlisted",
              },
              {
                label: "Published",
                description: `Will be listed on the available data sources listing with the name <b>${group}:${variant}</b>`,
                value: "published",
              },
            ]}
          />
          <AccessControlMode form={form} />
        </FormBody>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
