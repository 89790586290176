import React from "react"
import { Controller } from "react-hook-form"

import { IconButton, InputField } from "@ioxio-priv/dataspace-ui"

import { Icons } from "@/dsIcon"

export default function FormInput({
  name,
  label,
  readonly,
  form,
  index = 0,
  uriId = undefined,
  onRemoveClick = undefined,
  inputSx = {},
  children = undefined,
  required,
  tooltipText,
  placeholder,
  description,
  type = "",
  InputProps = {},
}) {
  let err = ""
  if (Object.prototype.hasOwnProperty.call(form.formState.errors, name)) {
    err = form.formState.errors[name].message
  }

  const inputElement = (
    <Controller
      name={name}
      control={form.control}
      defaultValue={""}
      render={({ field }) => (
        <InputField
          label={index === 0 ? label : ""}
          required={required}
          placeholder={placeholder}
          tooltipText={index === 0 ? tooltipText : undefined}
          testId={label}
          name={name}
          readonly={readonly}
          handleChange={field.onChange}
          error={err}
          type={type}
          description={description}
          baseProps={{ sx: inputSx, ...field, InputProps }}
        >
          {children}
        </InputField>
      )}
    />
  )
  if (onRemoveClick) {
    return (
      <Controller
        name={name}
        control={form.control}
        defaultValue={""}
        render={({ field }) => (
          <InputField
            label={index === 0 ? label : ""}
            required={required}
            placeholder={placeholder}
            tooltipText={index === 0 ? tooltipText : undefined}
            testId={label}
            name={name}
            handleChange={field.onChange}
            error={err}
            baseProps={{ sx: inputSx, ...field }}
          >
            <IconButton
              color={"error"}
              icon={Icons.delete}
              iconVariant={"outlined"}
              onClick={() => onRemoveClick(uriId)}
            />
          </InputField>
        )}
      />
    )
  }
  return inputElement
}
