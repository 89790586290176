import * as React from "react"

import { styled } from "@ioxio-priv/dataspace-ui"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"

export default function Spinner({ color, size, thickness }) {
  return (
    <SpinnerGrid>
      <CircularProgress color={color} size={size} thickness={thickness} />
    </SpinnerGrid>
  )
}

const SpinnerGrid = styled(Grid)`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
