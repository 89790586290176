import API from "./API"

class ApiTokenAPI extends API {
  async listAvailableSources() {
    return await this.makeRequest(`${this.baseUrl}/v1/access-control-keys/sources/`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async listAccessControlKeys(dsi, group) {
    let url = `${this.baseUrl}/v1/access-control-keys/?`
    url += new URLSearchParams({ dsi, group }).toString()
    return await this.makeRequest(url, {
      method: "GET",
      headers: this.headers,
    })
  }

  async refreshAccessControlKey({ group, dsi, accessControlKey }) {
    return await this.makeRequest(`${this.baseUrl}/v1/access-control-keys/refresh`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({ group, dsi, accessControlKey }),
    })
  }

  async fetchApiToken({ group, dsi, accessControlKey }) {
    return await this.makeRequest(`${this.baseUrl}/v1/api-token/request`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({ sub: group, aud: dsi, accessControlKey }),
    })
  }
}

const instance = new ApiTokenAPI()

export default instance
