import { useEffect, useState } from "react"

import ROUTES from "../constants/routes.json"
import AuthAPI from "../services/authAPI"

export default function useCodeExchange(history, queryParams) {
  const { LOGIN } = ROUTES
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [afterLoginRedirect, setAfterLoginRedirect] = useState("")
  const [loading, setLoading] = useState(true)
  const { code, state, nonce } = queryParams

  useEffect(() => {
    ;(async () => {
      const { ok, data, status, error } = await AuthAPI.doLoginCodeExchange({
        code,
        state,
        nonce,
      })
      if (ok) {
        if (data.ok) {
          setLoading(false)
          if (data.termsAccepted) {
            setTermsAccepted(true)
          }
          if (data.afterLoginRedirect) {
            setAfterLoginRedirect(data.afterLoginRedirect)
          }
        }
      } else {
        if (status === 403) {
          const { ok, data } = await AuthAPI.getUserData()
          setLoading(false)
          if (ok && data.loggedIn && data.termsAccepted) {
            setTermsAccepted(true)
          } else {
            history.push({
              pathname: LOGIN,
              state: { error: "Authentication failed" },
            })
          }
        } else {
          history.push({
            pathname: LOGIN,
            state: { error: error },
          })
        }
      }
    })()
  }, [history, code, state, nonce])

  return { loading, termsAccepted, setTermsAccepted, afterLoginRedirect }
}
