import React from "react"

import { Icon, IconLink, LinkButton } from "@ioxio-priv/dataspace-ui"
import { Badge, Box, Grid, styled, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { Icons } from "@/dsIcon"

export default function MyDataSourceCard({ dataSource }) {
  const { definition, source, state, lastErrorTime, lastReadErrorTime } = dataSource
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  function getIcon(state) {
    if (state === "published") {
      return "eye"
    } else if (state === "unlisted") {
      return "eye-closed"
    } else if (state === "private") {
      return "lock"
    }
  }

  function determineBadge() {
    if (!lastErrorTime) {
      return
    } else {
      if (!lastReadErrorTime) {
        return "!"
      } else {
        if (lastReadErrorTime < lastErrorTime) {
          return "!"
        } else {
          return
        }
      }
    }
  }
  return (
    <PrivateDataSourceGrid
      key={definition + source}
      data-testid={`${definition}-${source}`}
      container
      item
    >
      <Grid item container xs={8} sm={9}>
        <Wrapper>
          <Typography data-testid={definition} variant="h3">
            {definition}
          </Typography>
          <Typography data-testid={source} variant="subtitle1">
            {source}
          </Typography>
        </Wrapper>
      </Grid>
      <ButtonGrid item xs={4} sm={3}>
        {smallScreen ? (
          <ErrorBadge
            badgeContent={determineBadge()}
            slotProps={{
              badge: {
                "data-testid": `err-badge-${definition}-${source}`,
              },
            }}
            color="error"
          >
            <IconLink
              baseProps={{
                "data-testid": `edit-${source}`,
              }}
              href={`/sources/edit/${definition}/${source}`}
              icon={Icons.edit}
            />
          </ErrorBadge>
        ) : (
          <ErrorBadge
            badgeContent={determineBadge()}
            slotProps={{
              badge: {
                "data-testid": `err-badge-${definition}-${source}`,
              },
            }}
            color="error"
          >
            <LinkButton
              baseProps={{
                "data-testid": `edit-${source}`,
              }}
              icon={Icons.edit}
              iconVariant="outlined"
              key={"edit"}
              href={`/sources/edit/${definition}/${source}`}
            >
              Edit
            </LinkButton>
          </ErrorBadge>
        )}
      </ButtonGrid>
      <State>
        <StateIcon variant="outlined" name={getIcon(state)} /> <p>{state}</p>
      </State>
    </PrivateDataSourceGrid>
  )
}

const PrivateDataSourceGrid = styled(Grid)`
  margin: ${(p) => p.theme.spacing(1.5)} 0;
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  border-radius: 0.3125rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: ${(p) => p.theme.palette.secondary.light};
  padding: ${(p) => p.theme.spacing(1)};
`

const State = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(0.5)};
  margin-top: 1rem;
  color: ${(p) => p.theme.palette.secondary.main};
  background-color: ${(p) => p.theme.palette.primary.light};
  padding: ${(p) => p.theme.spacing(0.5)};
  border-radius: 20px;
  text-transform: capitalize;
`

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing(0.5)};
`

const ButtonGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const StateIcon = styled(Icon)`
  font-size: 20px;
`

const ErrorBadge = styled(Badge)`
  .MuiBadge-badge {
    font-weight: 700;
  }
`
