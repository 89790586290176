import API from "./API"

class DefinitionsAPI extends API {
  async getTags() {
    return await this.makeRequest(`${this.baseUrl}/v2/definitions/tags`, {
      method: "GET",
      headers: this.headers,
    })
  }
}

const instance = new DefinitionsAPI()

export default instance
