import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import ROUTES from "../constants/routes.json"
import DataSourceGroupsAPI from "../services/dataSourceGroupsAPI"

export default function useMyGroups() {
  const { LOGIN } = ROUTES
  const history = useHistory()
  const [myGroups, setMyGroups] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const { ok, data, error, status } = await DataSourceGroupsAPI.getMyGroups()
      if (ok) {
        setMyGroups(data.groups)
        setLoading(false)
      } else {
        if (status === 403) {
          history.push({
            pathname: LOGIN,
            state: { error: error },
          })
        }
        toast.error(error)
        setMyGroups([])
      }
    })()
  }, [history])

  return { myGroups, loading, setMyGroups }
}
