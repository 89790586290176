import { useContext, useEffect, useState } from "react"

import LayoutOptsContext from "../context/LayoutOptsContext"

export default function useLayoutOpts(navbar, footer) {
  const { layoutOpts, setLayoutOpts } = useContext(LayoutOptsContext)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (layoutOpts.nav !== navbar || layoutOpts.footer !== footer) {
      setLayoutOpts({ nav: navbar, footer: footer })
    }
    setLoading(false)
  }, [navbar, footer, layoutOpts, setLayoutOpts, setLoading])

  return loading
}
