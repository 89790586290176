import React from "react"

import { ErrorCode } from "@ioxio-priv/dataspace-ui"

import useLayoutOpts from "@/hooks/useLayoutOpts"
import useToast from "@/hooks/useToast"
import { getUrlParams } from "@/utilities"

export default function Error({
  location,
  status = 404,
  error = "This page does not exist.",
}) {
  const { message, status_code } = getUrlParams(["message", "status_code"])

  if (message) {
    error = message
  }

  if (status_code) {
    status = parseInt(status_code)
  }

  // render navbar and footer
  useLayoutOpts(true, true)

  // Initialize toasts and render any in the state
  useToast(location.state)

  return <ErrorCode code={status} message={error} />
}
