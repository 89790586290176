import * as React from "react"
import { Route } from "react-router-dom"

import FullPageSpinner from "@/containers/FullPageSpinner"
import useUserData from "@/hooks/useUserData"

export default function EnhancedPublicRoute({
  component: Component,
  componentParams,
  path,
  exact = true,
}) {
  const { meRequestData, loading } = useUserData()

  if (loading) {
    return <FullPageSpinner />
  } else {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props) => (
          <Component userData={meRequestData} {...props} {...componentParams} />
        )}
      />
    )
  }
}
