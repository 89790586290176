import React from "react"

import { FormDialog } from "@ioxio-priv/dataspace-ui"

import { API_TOKEN_VALIDITY_TIME } from "@/utilities"

export default function RemoveAllowedGroupModal({
  group,
  isOpen,
  closeModal,
  asyncOnSubmit,
}) {
  async function _asyncOnSubmit() {
    const resp = await asyncOnSubmit(group)
    closeModal()
    return resp
  }

  return (
    <FormDialog
      isOpen={isOpen}
      onClose={closeModal}
      title={`Remove access`}
      description={
        <>
          Please note that any already generated API tokens for the group <b>{group}</b>{" "}
          are valid for up to <b>{API_TOKEN_VALIDITY_TIME}</b>.
        </>
      }
      asyncOnClick={{ asyncFn: _asyncOnSubmit }}
      confirmText={"Remove"}
      submitButtonProps={{
        icon: "delete",
        iconVariant: "outlined",
        color: "error",
        baseProps: {
          "data-testid": "remove-confirmation-btn",
        },
      }}
    />
  )
}
