import Joi from "joi"
import { parseDomain, ParseResultType } from "parse-domain"

export const SourceUrlValidator = Joi.extend((joi) => {
  return {
    base: joi.string().uri({ scheme: "https" }),
    type: "sourceURL",
    messages: {
      "url.port": "Port is not allowed",
      "url.username": "Username is not allowed",
      "url.password": "Password is not allowed",
      "url.fragment": "Fragment (#) is not allowed",
      "url.query": "Query parameters are not allowed",
      "url.protocol": "Only https URLs are allowed",
      "url.tld": "Top level domain (TLD) is required",
      "url.invalid": "Base URL must be a valid HTTPS URL e.g. https://example.com",
    },
    validate(value, helpers) {
      try {
        const { hash, password, port, protocol, search, username, host } = new URL(
          value
        )

        if (hash) {
          return { value, errors: helpers.error("url.fragment") }
        }
        if (protocol !== "https:") {
          return { value, errors: helpers.error("url.protocol") }
        }
        if (port) {
          return { value, errors: helpers.error("url.port") }
        }
        if (password) {
          return { value, errors: helpers.error("url.password") }
        }
        if (search) {
          return { value, errors: helpers.error("url.query") }
        }
        if (username) {
          return { value, errors: helpers.error("url.username") }
        }
        if (host) {
          const parseResult = parseDomain(host)
          if (
            parseResult.type !== ParseResultType.Ip &&
            parseResult.type !== ParseResultType.Listed
          ) {
            return { value, errors: helpers.error("url.tld") }
          }
        }
      } catch (e) {
        return { value, errors: helpers.error("url.invalid") }
      }
    },
  }
})
