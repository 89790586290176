import { Box, styled } from "@mui/material"

import Heading from "@/components/Heading"

export default function Header({ breadCrumb, children, title }) {
  return (
    <Wrapper>
      {breadCrumb}
      <Heading baseProps={{ "data-testid": "heading" }}>{title}</Heading>
      {children && <TitleDescription data-testid="helper">{children}</TitleDescription>}
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`

const TitleDescription = styled(Box)`
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
`
