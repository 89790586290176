import React, { useState } from "react"
import { toast } from "react-toastify"

import { FormDialog, InputField } from "@ioxio-priv/dataspace-ui"

import { API_TOKEN_VALIDITY_TIME } from "@/utilities"

export default function AddAllowedGroupModal({ isOpen, closeModal, asyncOnSubmit }) {
  const [error, setError] = useState("")
  const [group, setGroup] = useState("")

  async function _asyncOnSubmit() {
    setError("")
    const { ok, error } = await asyncOnSubmit(group)
    if (ok) {
      toast.success(`Granted group ${group} access to this data source`)
      closeModal()
    } else {
      setError(error)
    }
    return { ok }
  }

  return (
    <FormDialog
      isOpen={isOpen}
      onClose={closeModal}
      title={"Add access"}
      description={
        <>
          <p>
            Application developers of the added group will be able to self-manage their
            own API tokens.
          </p>
          <p>
            Please note that API tokens will remain valid for up to{" "}
            {API_TOKEN_VALIDITY_TIME} after access is removed.
          </p>
        </>
      }
      asyncOnClick={{ asyncFn: _asyncOnSubmit }}
      confirmText={"Add"}
      submitButtonProps={{
        icon: "add",
        color: "success",
        baseProps: {
          "data-testid": "add-confirmation-btn",
        },
      }}
    >
      <InputField
        label={"Group name"}
        name={"groupName"}
        error={error}
        handleChange={(e) => e && setGroup(e.target.value)}
      />
    </FormDialog>
  )
}
