import Joi from "joi"

export const SCHEMA = Joi.object({
  partyConfigurationDomain: Joi.string()
    .domain()
    .allow("")
    .options({ abortEarly: true })
    .message("This field must contain a valid domain name"),
  gdprContact: Joi.string()
    .email({ tlds: { allow: false } })
    .allow("")
    .options({ abortEarly: true })
    .message("Must be a valid email address"),
}).required()
