import React from "react"

import { theme1 as theme } from "@ioxio-priv/dataspace-ui"
import { ReactComponent as DataspaceLogo } from "@ioxio-priv/dataspace-ui/src/assets/theme1logo.svg"
import { getMenuItems, getRuntimeSettings } from "@ioxio-priv/dataspace-utils"

import { baseConfig, baseDsConfig } from "./base"

export const runtimeSettings = getRuntimeSettings()
export const menuSections = getMenuItems()

// Dataspace specific configuration
export const dsConfig = {
  ...baseDsConfig,
  components: {
    "Accession Agreement": "https://testbed.fi/accession-agreement",
    ...baseDsConfig.components,
  },
  navFooterLinks: {
    "Privacy Policy": "https://testbed.fi/privacy",
  },
  dataspaceVariant: "Finnish data economy testbed",
  dataspaceLogo: <DataspaceLogo />,
  footerExternalLinks: [
    { name: "IOXIO", url: "https://ioxio.com/" },
    { name: "GitHub", url: "https://github.com/Virtual-Finland" },
  ],
}

// Application specific configuration
export const config = {
  ...baseConfig,
  theme: theme,

  sourcesRepo: "https://github.com/Virtual-Finland/definitions",
  definitions: "https://github.com/Virtual-Finland/definitions/tree/main/DataProducts",
  termsOfService: "https://testbed.fi/accession-agreement",
  termsOfServiceName: "Accession Agreement",
  privacyPolicy: "https://testbed.fi/privacy",
  developerResources: {
    title: "Developer resources",
    description: "Resources on how to build, integrate and innovate on Dataspaces.",
    sections: [
      {
        title: "Guides",
        description:
          "Tutorials on how to build on and integrate using our Dataspace technology.",
        icon: "basic-guides",
        links: [
          {
            label: "How to create a group",
            href: "https://ioxio.com/guides/how-to-create-a-group",
          },
          {
            label: "How to build an application",
            href: "https://ioxio.com/guides/how-to-build-an-application",
          },
          {
            label: "Verify consent in a data source",
            href: "https://ioxio.com/guides/verify-consent-in-a-data-source",
          },
          {
            label: "How to create data definitions",
            href: "https://ioxio.com/guides/how-to-create-data-definitions",
          },
          {
            label: "Use login portal in your application",
            href: "https://ioxio.com/guides/use-login-portal-in-your-applications",
          },
          {
            label: "How to build a data source",
            href: "https://ioxio.com/guides/how-to-build-a-data-source",
          },
          {
            label: "Verify id_token in a data source",
            href: "https://ioxio.com/guides/verify-id-token-in-a-data-source",
          },
        ],
      },
      {
        title: "APIs",
        icon: "basic-guides",
        description:
          "We offer API docs in multiple formats to ensure they are readable for anyone, no matter their background.",
        links: [
          {
            label: "Product gateway - Swagger UI",
            href: "https://gateway.testbed.fi/docs",
          },
          {
            label: "Developer portal - Swagger UI",
            href: "https://developer.testbed.fi/docs",
          },
          {
            label: "Virtual Finland data definitions viewer",
            href: "https://definitions.testbed.fi/",
          },
        ],
      },
      {
        title: "Video guides",
        icon: "video-guides",
        description:
          "If you prefer to learn by watching and listening check out our videos below.",
        links: [
          {
            label: "Defining data products",
            href: "https://www.youtube.com/watch?v=yPzN04ICsbw&t=194s",
          },
          {
            label: "Productizing data",
            href: "https://www.youtube.com/watch?v=f-f6P_-8zoQ",
          },
        ],
      },
      {
        title: "Other available resources",
        icon: "other-guides",
        description: "Other available resources",
        links: [
          {
            label: "Virtual Finland GitHub",
            href: "https://github.com/Virtual-Finland",
          },
          {
            label: "IOXIO Dataspace GitHub",
            href: "https://github.com/ioxio-dataspace",
          },
          {
            label: "Party configuration schema",
            href: "https://docs.ioxio.dev/schemas/party-configuration/",
          },
          {
            label: "Consent request token schema",
            href: "https://docs.ioxio.dev/schemas/consent-request-token/",
          },
          {
            label: "Consent token schema",
            href: "https://docs.ioxio.dev/schemas/consent-token/",
          },
          {
            label: "Dataspace configuration schema",
            href: "https://docs.ioxio.dev/schemas/dataspace-configuration/",
          },
        ],
      },
    ],
  },
  // Don't update these as they are the VF variants
  guideDataSources: "https://miro.com/app/board/uXjVOBHtQdI=/",
  guideApplications: "https://miro.com/app/board/uXjVOBHtQcU=/",
}

if (runtimeSettings.CONSENT_PROVIDER_URL) {
  baseConfig.developerResources.sections[1].links.splice(2, 0, {
    label: "Consent portal - Swagger UI",
    href: "https://consent.testbed.fi/docs",
  })
}
