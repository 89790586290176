import Joi from "joi"

function isLocalhost(value, helper) {
  if (value.startsWith("http://localhost:" || value.startsWith("http://localhost/"))) {
    return true
  }
  return helper.message("Local URLs must start with http://localhost")
}

const RedirectUriSchema = Joi.alternatives()
  .conditional(Joi.string().uri({ scheme: "http" }), {
    then: Joi.string().uri({ scheme: "http" }).custom(isLocalhost),
    otherwise: Joi.string().uri({ scheme: "https" }),
  })
  .required()
  .messages({
    "string.empty": "This field is required",
    "string.uri": "It must be a valid https:// or http://localhost URL",
    "string.uriCustomScheme": "It must be a valid https:// or http://localhost URL",
  })

export function makeSchema(redirectUriIds, logoutRedirectUriIds) {
  const obj = {
    name: Joi.string()
      .trim()
      .min(2)
      .max(64)
      .pattern(new RegExp("^[a-zA-Z0-9 _-]*$"))
      .required()
      .messages({
        "string.empty": "This field is required",
        "string.min": "Application name must have at least 2 characters",
        "string.max": "Application name must not have more than 64 characters",
        "string.pattern.base":
          "Application names must consist only of letters A-Z, numbers, spaces, underscores, and hyphens. Leading and trailing spaces will be removed.",
      }),
    appUrl: Joi.string().uri().allow(null, "").messages({
      "string.uri": "It must be a valid URL",
    }),
    privacyPolicy: Joi.string().uri().allow(null, "").messages({
      "string.uri": "It must be a valid URL",
    }),
    group: Joi.string()
      .required()
      .messages({
        "string.empty": "Group is required",
        "any.required": "Group is required",
      })
      .options({ abortEarly: true }),
  }
  for (let id of redirectUriIds) {
    obj[`redirectUri${id}`] = RedirectUriSchema
  }
  for (let id of logoutRedirectUriIds) {
    obj[`logoutRedirectUri${id}`] = RedirectUriSchema
  }
  return Joi.object(obj)
}

export function makeThirdPartySchema() {
  const obj = {
    name: Joi.string().required().messages({ "string.empty": "Name is required" }),
    appUrl: Joi.string().uri().allow(null, "").messages({
      "string.uri": "It must be a valid URL",
    }),
    privacyPolicy: Joi.string().uri().allow(null, "").messages({
      "string.uri": "It must be a valid URL",
    }),
    clientID: Joi.string().required().guid().messages({
      "string.guid": "It must be a valid UUID",
      "string.empty": "Client ID is required",
    }),
    group: Joi.string()
      .required()
      .messages({
        "string.empty": "Group is required",
        "any.required": "Group is required",
      })
      .options({ abortEarly: true }),
  }
  return Joi.object(obj)
}

export function getFormData(form, redirectUriIds, logoutRedirectUriIds) {
  const formData = form.getValues()
  // convert redirect URIs from the form to API format
  const rUris = redirectUriIds.map((id) => formData[`redirectUri${id}`])
  const lrUris = logoutRedirectUriIds.map((id) => formData[`logoutRedirectUri${id}`])

  return {
    name: formData.name.trim(),
    redirectUris: rUris,
    logoutRedirectUris: lrUris,
    appUrl: formData.appUrl || null,
    privacyPolicy: formData.privacyPolicy || null,
    group: formData.group,
  }
}

export function getThirdPartyFormData(form) {
  const formData = form.getValues()

  return {
    name: formData.name,
    appUrl: formData.appUrl || null,
    privacyPolicy: formData.privacyPolicy || null,
    group: formData.group,
    clientId: formData.clientID || null,
  }
}
