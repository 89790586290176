import React, { useState } from "react"

import { IconButton, LinkBox, styled } from "@ioxio-priv/dataspace-ui"
import { Collapse, Typography } from "@mui/material"
import Box from "@mui/material/Box"

import { HorizontalLine } from "@/commonStyles"
import { Icons } from "@/dsIcon"

export default function ResourcesBox({
  title = "Resources",
  links,
  collapsible = false,
}) {
  const Wrapper = styled(Box)({
    marginBottom: "1.5rem",
  })
  const [isOpen, setIsOpen] = useState(!collapsible)

  return (
    <Wrapper data-testid={"resources"}>
      <Header>
        <Title collapsible={collapsible} variant="subtitle1">
          {title}:
        </Title>
        {collapsible && (
          <IconButton
            icon={isOpen ? Icons.arrowUp : Icons.arrowDown}
            onClick={() => setIsOpen(!isOpen)}
            variant="contained"
            color={isOpen ? "secondary" : "primary"}
          />
        )}
      </Header>
      <Collapse in={isOpen}>
        <Links collapsible={collapsible}>
          {links.map(({ text, link }) => (
            <LinkBox target={"_blank"} key={link} href={link}>
              {text}
            </LinkBox>
          ))}
        </Links>
      </Collapse>
      {collapsible && <CustomHorizontalLine />}
    </Wrapper>
  )
}

const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Title = styled(Typography)`
  margin-bottom: ${(p) => (p.collapsible ? 0 : "1rem")};
`

const CustomHorizontalLine = styled(HorizontalLine)`
  margin-top: 0.5rem;
`

const Links = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: ${(p) => (p.collapsible ? "1rem" : 0)};
`
