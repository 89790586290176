import { config } from "@/settings"

import API from "./API"

const API_BASE_PATH = config.externalAppManagement ? "v1/external-apps" : "v1/apps"

class AppsAPI extends API {
  constructor() {
    super()
    this.listApplications = this.listApplications.bind(this)
  }

  async listApplications() {
    return await this.makeRequest(`${this.baseUrl}/${API_BASE_PATH}`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async createApplication(data) {
    return await this.makeRequest(`${this.baseUrl}/${API_BASE_PATH}`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async removeApplication(clientId) {
    return await this.makeRequest(`${this.baseUrl}/${API_BASE_PATH}/${clientId}`, {
      method: "DELETE",
      headers: this.headers,
    })
  }

  async updateApplication(clientId, payload) {
    return await this.makeRequest(`${this.baseUrl}/${API_BASE_PATH}/${clientId}`, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(payload),
    })
  }

  async getApplication(clientId) {
    return await this.makeRequest(`${this.baseUrl}/${API_BASE_PATH}/${clientId}`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async refreshSecrets(clientId, secret) {
    return await this.makeRequest(
      `${this.baseUrl}/v1/apps/${clientId}/refresh-secret`,
      {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify({ clientSecret: secret }),
      }
    )
  }
}

const instance = new AppsAPI()

export default instance
