import React from "react"

import { IconButton } from "@ioxio-priv/dataspace-ui"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

import { Icons } from "../dsIcon"

export default function AdvancedFieldControl({ isOpen, setIsOpen }) {
  return (
    <Control>
      <Typography variant="subtitle1">Advanced</Typography>
      <IconButton
        baseProps={{
          "data-testid": "advanced-btn",
        }}
        icon={isOpen ? Icons.arrowUp : Icons.arrowDown}
        onClick={() => setIsOpen(!isOpen)}
        variant="outlined"
        color="secondary"
      />
    </Control>
  )
}

const Control = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`
