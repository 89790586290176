import React from "react"

import { Grid, styled } from "@mui/material"

import { HorizontalLine } from "@/commonStyles"

const Form = ({ children, leftButtons, rightButtons, showButtons = true }) => {
  async function onSubmit(evt) {
    evt.preventDefault()
  }
  return (
    <FormWrapper onSubmit={onSubmit} showButtons={showButtons}>
      {children}
      {showButtons && (
        <>
          <HorizontalLine />
          <FormFooter>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              gap={1}
            >
              <Grid item>{leftButtons}</Grid>
              <Grid item display="flex" gap={1}>
                {rightButtons}
              </Grid>
            </Grid>
          </FormFooter>
        </>
      )}
    </FormWrapper>
  )
}

const FormWrapper = styled("form")`
  background-color: ${(p) => p.theme.palette.secondary.light};
  border-radius: 0.3125rem;
  padding-bottom: ${(p) => (p.showButtons ? "0" : "0.4rem")};
`

const FormFooter = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
`

export default Form
