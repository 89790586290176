import { useContext, useEffect, useState } from "react"

import { AuthContext } from "../context/AuthContext"
import AuthAPI from "../services/authAPI"

export default function useUserData() {
  const [meRequestData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)

  const authContent = useContext(AuthContext)

  useEffect(() => {
    let mounted = true
    if (authContent.user && authContent.user.name) {
      setUserData(authContent.user)
      setLoading(false)
    } else {
      ;(async () => {
        if (!mounted) return
        const { ok, data } = await AuthAPI.getUserData()
        if (!mounted) return
        if (ok && data.loggedIn) {
          setUserData(data)
          authContent.setUser(data)
          setLoading(false)
        } else {
          setUserData(null)
          setLoading(false)
        }
      })()
    }
    return () => (mounted = false)
  }, [])

  return { meRequestData, setUserData, loading }
}
