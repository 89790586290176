import * as React from "react"

import { Typography } from "@mui/material"

export default function Heading({ children, baseProps = {}, csx }) {
  if (!Object.prototype.hasOwnProperty.call(baseProps, "as")) {
    baseProps.as = baseProps.as || "h1"
  }
  return (
    <Typography variant="h1" component={baseProps.as} sx={csx} {...baseProps}>
      {children}
    </Typography>
  )
}
