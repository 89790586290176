import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"

import DataSourceAPI from "../services/dataSourceAPI"

export default function useGetDefinitions() {
  const history = useHistory()
  // mapping of `definition_vX.Y` -> definition
  const [definitions, setDefinitions] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const { ok, data, error } = await DataSourceAPI.getDataSourceDefinitions()
      if (ok) {
        // mapping of `definition_vX.Y` -> definition
        setDefinitions(
          data.definitions.reduce((acc, def) => {
            const key = def.version
              ? `${def.definition}_v${def.version}`
              : def.definition
            acc[key] = def
            return acc
          }, {})
        )
        setLoading(false)
      } else {
        setDefinitions([])
        toast.error(error)
      }
    })()
  }, [history])
  return { definitions, loading, setDefinitions }
}
