import API from "./API"

class AuthAPI extends API {
  async doLoginCodeExchange(data) {
    return await this.makeRequest(`${this.baseUrl}/auth/code-exchange`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async getUserData() {
    return await this.makeRequest(`${this.baseUrl}/auth/me`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async health() {
    return await this.makeRequest(`${this.baseUrl}/health`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async startLogin(data) {
    return await this.makeRequest(`${this.baseUrl}/auth/start-login`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }
}

const instance = new AuthAPI()

export default instance
