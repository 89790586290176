import React from "react"

import { styled } from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"

import Heading from "@/components/Heading"
import Spinner from "@/components/Spinner"
import useLayoutOpts from "@/hooks/useLayoutOpts"

const headingStyles = { mb: 3, mt: 3, fontSize: "1.8rem" }

export default function FullPageSpinner() {
  useLayoutOpts(false, false)

  return (
    <SpinnerBox>
      <Heading csx={headingStyles}>Loading...</Heading>
      <Spinner />
    </SpinnerBox>
  )
}

const SpinnerBox = styled(Box)`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
`
