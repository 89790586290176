import API from "./API"

class InvitesAPI extends API {
  async getMyGroupInvites() {
    return await this.makeRequest(`${this.baseUrl}/v1/invites`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async respondToInvite(inviteId, response) {
    return await this.makeRequest(
      `${this.baseUrl}/v1/invites/${inviteId}/${response}`,
      {
        method: "POST",
        headers: this.headers,
      }
    )
  }
}

const instance = new InvitesAPI()

export default instance
