import React, { useEffect } from "react"

import InitialLoading from "@/containers/InitialLoading"

export default function StartLogout({ history }) {
  useEffect(() => {
    window.location.pathname = `/api/auth/start-logout`
  }, [history])

  return <InitialLoading />
}
