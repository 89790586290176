import React from "react"

export function ApplicationsHelp() {
  return (
    <p>
      Some data sources may require authentication, and you need to register your
      application to integrate to the authentication system.
    </p>
  )
}
