import React, { useState } from "react"

import { FormDialog, IconButton, InputField } from "@ioxio-priv/dataspace-ui"

import { Icons } from "@/dsIcon"

import { copyToClipboard } from "../utilities"

function RefreshSecretModal({ isOpen, setIsOpen, name, description, asyncOnSubmit }) {
  return (
    <FormDialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      title={`Refresh ${name.toLowerCase()}?`}
      asyncOnClick={{ asyncFn: asyncOnSubmit }}
      confirmText={"Refresh"}
      submitButtonProps={{
        icon: "refresh",
        color: "success",
      }}
      description={description}
    ></FormDialog>
  )
}

export default function SecretInput({
  secret,
  required,
  name,
  modalDescription,
  index,
  tooltipText,
  asyncOnRefresh = async () => ({ ok: true }),
}) {
  const [isShown, setIsShown] = useState(false)
  const value = isShown
    ? secret
    : secret.substring(0, 5) + "*".repeat((secret.length || 16) - 5)
  const showBtnText = isShown ? "Hide" : "Show"

  const [isModalShown, setModalShown] = useState(false)

  function toggleIsShown() {
    setIsShown(!isShown)
  }

  return (
    <>
      <InputField
        value={value}
        required={required}
        tooltipText={index === 0 ? tooltipText : ""}
        label={index === 0 ? name : ""}
        name={name}
        readonly
      >
        <IconButton
          color={"secondary"}
          variant={"outlined"}
          icon={isShown ? Icons.eyeClosed : Icons.eye}
          iconVariant={"outlined"}
          title={isShown ? `Hide ${name.toLowerCase()}` : `Show ${name.toLowerCase()}`}
          onClick={toggleIsShown}
        >
          {showBtnText}
        </IconButton>
        <IconButton
          icon={Icons.cached}
          color={"secondary"}
          variant={"outlined"}
          title={`Refresh ${name.toLowerCase()}`}
          onClick={() => setModalShown(true)}
        />
        <IconButton
          icon={Icons.copy}
          color={"secondary"}
          variant={"outlined"}
          title={`Copy ${name.toLowerCase()}`}
          onClick={() =>
            copyToClipboard(secret, `${name} has been copied to the clipboard`)
          }
        />
      </InputField>
      <RefreshSecretModal
        isOpen={isModalShown}
        setIsOpen={setModalShown}
        name={name}
        description={modalDescription}
        asyncOnSubmit={() => asyncOnRefresh(secret)}
      />
    </>
  )
}
