import React from "react"

import { Breadcrumbs, styled } from "@ioxio-priv/dataspace-ui"
import { LinkBox } from "@ioxio-priv/dataspace-ui"
import { Box, Grid, SvgIcon, Typography } from "@mui/material"

import { ReactComponent as APIsIcon } from "@/assets/resources/api.svg"
import { ReactComponent as GuidesIcon } from "@/assets/resources/guides.svg"
import { ReactComponent as OtherResourcesIcon } from "@/assets/resources/others.svg"
import { ReactComponent as VideoGuidesIcon } from "@/assets/resources/video.svg"
import Header from "@/components/Header"
import { MetaData } from "@/components/MetaData"
import { BreadCrumbsNames, BreadCrumbsPath } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import { config } from "@/settings"

const ICONS = {
  "video-guides": VideoGuidesIcon,
  "basic-guides": GuidesIcon,
  "api-guides": APIsIcon,
  "other-guides": OtherResourcesIcon,
}

function validateIcons(sections) {
  for (let section of sections) {
    if (!Object.prototype.hasOwnProperty.call(ICONS, section.icon)) {
      throw new Error(
        `You provided an invalid icon name. Pick a valid name from this list: ${Object.keys(
          ICONS
        )}`
      )
    }
  }
}

function ResourcesSection(props) {
  const { icon, links, title, description } = props

  return (
    <Section>
      <SectionHeader>
        <SVGWrapper>
          <SvgIcon component={ICONS[icon]} />
        </SVGWrapper>
        <TitleWrapper>
          <Title variant="h2">{title}</Title>
        </TitleWrapper>
      </SectionHeader>
      <Description>{description}</Description>
      <ResourcesBox>
        {links.map((link) => (
          <LinkBox target={"_blank"} key={link.href} href={link.href}>
            {link.label}
          </LinkBox>
        ))}
      </ResourcesBox>
    </Section>
  )
}

export default function DeveloperResources() {
  const data = config.developerResources
  const sections = data?.sections || []
  sections.length > 0 && validateIcons(sections)

  return (
    <MainContentBox>
      <MetaData {...labels.meta.resources} />
      <Header
        breadCrumb={
          <Breadcrumbs
            current={BreadCrumbsNames.RESOURCES}
            paths={[BreadCrumbsPath.DEVELOPER_PORTAL]}
          />
        }
        title={data.title}
      >
        {data.description}
      </Header>
      {sections.length > 0 && (
        <ResourcesContainer container>
          {sections.map((section) => (
            <ResourcesSection key={section.title} {...section} />
          ))}
        </ResourcesContainer>
      )}
    </MainContentBox>
  )
}

const MainContentBox = styled(Box)`
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 50.125rem;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 100%;
  }
`

const ResourcesContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  row-gap: 3rem;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-top: 1.5rem;
  }
`

const Section = styled(Grid)`
  max-width: 20.625rem;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 100%;
  }
`

const SectionHeader = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
`

const SVGWrapper = styled(Box)`
  display: flex;
  height: 3.2rem;
  width: 3.2rem;

  svg {
    overflow: visible;
  }

  svg > path {
    fill: ${(p) => p.theme.palette.primary.main} !important;
  }
`

const TitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled(Typography)`
  margin: 0;
  padding: 0;
  color: ${(p) => p.theme.palette.primary.main} !important;
`

const Description = styled(Typography)`
  margin: 1.5rem 0 1rem;
  font-size: 1rem;
`

const ResourcesBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
