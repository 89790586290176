import React from "react"

export function GroupsHelp() {
  return (
    <p>
      Groups are used to organize your data products for both access control and vendor
      identification. They contain the party configuration for data product signature
      and consent portal integrations. The group name will be visible on the URL to
      request data from your data sources, so please use a clear name.
    </p>
  )
}
