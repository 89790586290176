import React from "react"
import { ToastContainer } from "react-toastify"

import { Layout as DataspaceUILayout, useDsConfig } from "@ioxio-priv/dataspace-ui"

import ROUTES from "@/constants/routes"
import { AuthContext } from "@/context/AuthContext"
import { dsConfig, menuSections } from "@/settings"

export default function Layout({ children }) {
  useDsConfig(dsConfig)

  const authContext = React.useContext(AuthContext)

  return (
    <DataspaceUILayout
      dataspaceHome={dsConfig.dataspaceHome}
      user={authContext.user || undefined}
      logoutPath={ROUTES.START_LOGOUT}
      menuSections={menuSections}
    >
      <ToastContainer />
      {children}
    </DataspaceUILayout>
  )
}
