import { createContext, useContext, useState } from "react"

const SourcesSearchContext = createContext()

export const useSearchUrl = () => useContext(SourcesSearchContext)

export function SourcesSearchProvider({ children }) {
  const [searchUrl, setSearchUrl] = useState()

  return (
    <SourcesSearchContext.Provider value={{ searchUrl, setSearchUrl }}>
      {children}
    </SourcesSearchContext.Provider>
  )
}
