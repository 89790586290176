import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import {
  Button,
  IconButton,
  IconLink,
  InputField,
  LinkButton,
  Submit,
} from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import { styled } from "@mui/material/styles"

import { SCHEMA } from "./validation"
import { FormBody, HorizontalLine } from "../../commonStyles"
import AdvancedFieldControl from "../../components/AdvancedFieldControl"
import Form from "../../components/Form"
import { labels } from "../../constants/labels"
import { Icons } from "../../dsIcon"
import { setEmptyStringsToNull } from "../../utilities"
import { toastError } from "../../utilities/errors"
import FormInput from "../FormInput"

export default function EditGroupForm({
  group,
  initialFormValues,
  asyncOnSubmit = async () => ({ ok: true }),
  setRemoveFormShown,
  isOwner,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(SCHEMA),
    defaultValues: {
      partyConfigurationDomain: initialFormValues.partyConfigurationDomain,
      gdprContact: initialFormValues.gdprContact,
    },
  })

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to edit the group", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()

    if (!isFormValid) {
      return {
        ok: false,
      }
    }

    const data = form.getValues()

    const payload = {
      ...data,
      group,
    }
    const transformedPayload = setEmptyStringsToNull(payload)

    return asyncOnSubmit(transformedPayload, handleBackendErrors)
  }

  return (
    <Form
      onSubmit={onSubmit}
      showButtons={isOwner}
      rightButtons={
        <>
          <ButtonContainer>
            <LinkButton variant={"outlined"} icon={Icons.cancel} href={`/groups`}>
              Cancel
            </LinkButton>
            <Submit
              icon={Icons.save}
              color="success"
              asyncOnClick={{
                asyncFn: _asyncOnSubmit,
              }}
            >
              Save
            </Submit>
          </ButtonContainer>
          <IconButtonContainer>
            <IconLink icon={Icons.cancel} href={"/groups"} variant={"outlined"} />
            <Submit
              icon={Icons.success}
              label={"Save"}
              iconOnly
              color="success"
              asyncOnClick={{
                asyncFn: _asyncOnSubmit,
              }}
            />
          </IconButtonContainer>
        </>
      }
      leftButtons={
        <>
          <ButtonContainer>
            <Button
              baseProps={{
                "data-testid": `delete-${initialFormValues.group}`,
              }}
              color={"error"}
              onClick={() => setRemoveFormShown(true)}
              icon={Icons.delete}
              iconVariant="outlined"
            >
              Delete
            </Button>
          </ButtonContainer>
          <IconButtonContainer>
            <IconButton
              baseProps={{
                "data-testid": `icon-delete-${initialFormValues.group}`,
              }}
              color={"error"}
              onClick={() => setRemoveFormShown(true)}
              icon={Icons.delete}
              iconVariant="outlined"
            />
          </IconButtonContainer>
        </>
      }
    >
      <FormBody>
        <InputField
          baseProps={{
            tabIndex: -1,
            "data-testid": "group",
          }}
          readonly={true}
          name={"group"}
          label={"Name"}
          tooltipText={labels.group.fields.name.tooltipText}
          value={group}
          required={true}
        />
        <FormInput
          baseProps={{
            autoCapitalize: "none",
          }}
          readonly={!isOwner}
          label={labels.group.fields.gdprContact.label}
          tooltipText={labels.group.fields.gdprContact.tooltipText}
          name={"gdprContact"}
          placeholder={"Email"}
          form={form}
        />
      </FormBody>
      <HorizontalLine />
      <AdvancedFieldControl isOpen={isOpen} setIsOpen={setIsOpen} />
      <Collapse in={isOpen}>
        <InputBox>
          <FormInput
            baseProps={{
              autoCapitalize: "none",
            }}
            readonly={!isOwner}
            label={labels.group.fields.partyConfigurationDomain.label}
            name={"partyConfigurationDomain"}
            placeholder={"example.com"}
            tooltipText={labels.group.fields.partyConfigurationDomain.tooltipText}
            form={form}
          />
        </InputBox>
      </Collapse>
    </Form>
  )
}

const InputBox = styled(Box)`
  padding: 0 1rem 1rem;
`

const ButtonContainer = styled(Box)`
  display: flex;
  gap: 1rem;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: none;
  }
`

const IconButtonContainer = styled(Box)`
  display: flex;
  gap: 1rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`
