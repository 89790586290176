import { ErrorCard, Icon, styled } from "@ioxio-priv/dataspace-ui"
import { Grid } from "@mui/material"

import { MailLink } from "@/commonStyles"
import FormInput from "@/components/FormInput"
import FormSelect from "@/components/FormSelect"
import { config } from "@/settings"

export default function InputWithDropdownForm({
  inputType = "text",
  wrapperSx = {},
  disabledInputField = false,
  selectOptions,
  inputName,
  selectName,
  inputPlaceholder,
  selectPlaceholder,
  inputRequired,
  selectRequired,
  form,
  inviteError,
  showInviteOnlyInfo = false,
}) {
  return (
    <Grid container sx={wrapperSx}>
      <Grid item xs={9}>
        <FormInput
          name={inputName}
          form={form}
          placeholder={inputPlaceholder}
          required={inputRequired}
          type={inputType}
          InputProps={{
            classes: {
              notchedOutline: "input-with-dropdown",
            },
            disabled: disabledInputField,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormSelect
          form={form}
          name={selectName}
          required={selectRequired}
          options={selectOptions}
          selectSx={selectSx}
          placeholder={selectPlaceholder}
        />
      </Grid>
      {inviteError && (
        <ErrorCardWrapper>
          <ErrorCard>{inviteError}</ErrorCard>
        </ErrorCardWrapper>
      )}
      {config.inviteOnly && showInviteOnlyInfo && (
        <InviteOnlyInfo>
          <Icon
            name="admin"
            variant="outlined"
            baseProps={{ sx: { fontSize: 40 } }}
          ></Icon>
          <h3>This dataspace is in invite-only mode.</h3>
          <p>
            Users you invite to a group still need to be separately invited as
            developers, so ensure they have a developer account before sending them an
            invite. If you are unsure, please contact support at{" "}
            <MailLink href={`mailto:${config.supportEmail}`}>
              {config.supportEmail}
            </MailLink>
          </p>
        </InviteOnlyInfo>
      )}
    </Grid>
  )
}

const selectSx = {
  borderRadius: "0 0.3125rem 0.3125rem 0",
}

const ErrorCardWrapper = styled("div")`
  margin-top: 0.5rem;
  width: 100%;
`

const InviteOnlyInfo = styled("div")`
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 1rem;
  margin-top: 0.5rem;
  line-height: 110%;

  h3 {
    font-weight: 500;
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  p {
    font-size: 0.75rem;
  }
`
