import React, { useState } from "react"

import { FormDialog, InputField } from "@ioxio-priv/dataspace-ui"

export default function RemoveFormModal({
  isOpen,
  setIsOpen,
  name,
  asyncOnSubmit,
  type,
  title,
}) {
  const [currentName, setCurrentName] = useState("")
  const isDisabled = currentName !== name

  return (
    <FormDialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      title={title}
      description={
        <>
          Confirm you wish to delete the {type} by typing in its name:{" "}
          <strong>{name}</strong>
        </>
      }
      asyncOnClick={{ asyncFn: asyncOnSubmit }}
      confirmText={"Delete"}
      submitButtonProps={{
        icon: "delete",
        color: "error",
        disabled: isDisabled,
        baseProps: {
          "data-testid": "delete-confirmation-btn",
        },
      }}
    >
      <InputField
        label={"Name"}
        name={"nameToDelete"}
        handleChange={(e) => e && setCurrentName(e.target.value)}
      />
    </FormDialog>
  )
}
