import React from "react"

const AuthContext = React.createContext()

export { AuthContext }

export function AuthProvider({ children }) {
  const [state, setState] = React.useState({ user: null })

  return (
    <AuthContext.Provider
      value={{
        ...state,
        // user email defaults to name if unset
        setUser: (u) =>
          setState({ ...state, user: { ...u, email: u.email || u.name } }),
        isLoggedIn: () => !!(state.user && state.user.name),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
