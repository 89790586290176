import { useEffect } from "react"
import { toast } from "react-toastify"

export default function useToast(state) {
  useEffect(() => {
    if (state) {
      if (state.error) {
        toast.error(state.error)
      }
      if (state.status) {
        toast.info(state.status)
      }
      if (state.success) {
        toast.success(state.success)
      }
    }
  }, [state])
}
