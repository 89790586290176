import React from "react"
import { toast } from "react-toastify"

import { CreateGroupForm } from "@/components/CreateGroupForm"
import { BreadCrumbsNames } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import GroupsWrapper from "@/containers/GroupsWrapper"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import DataSourceGroupsAPI from "@/services/dataSourceGroupsAPI"

export function GroupsCreate({ history }) {
  // render navbar and footer
  useLayoutOpts(true, true)

  async function createGroup(data, handleErrors) {
    const { ok, error } = await DataSourceGroupsAPI.addGroup(data)
    if (ok) {
      toast.success(`Successfully created group "${data.group}"`)
      history.push({
        pathname: `/groups/edit/${data.group}`,
      })
    } else {
      handleErrors(error)
    }
    return { ok }
  }

  return (
    <GroupsWrapper
      meta={labels.meta.createGroup}
      breadCrumbs={BreadCrumbsNames.CREATE_GROUPS}
      title={"Create a new group"}
    >
      <CreateGroupForm asyncOnSubmit={createGroup} />
    </GroupsWrapper>
  )
}
