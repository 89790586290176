import React from "react"

import { InlineLink } from "@/components/InlineLink"
import ROUTES from "@/constants/routes.json"

export function SourcesHelp() {
  return (
    <p>
      Your published data sources will show up for other developers under the{" "}
      <InlineLink href={ROUTES.SOURCES_AVAILABLE}>available data sources</InlineLink>{" "}
      page.
    </p>
  )
}
