import * as React from "react"

import { Typography } from "@mui/material"

const TextStyleSx = {
  fontSize: "1rem",
}

export default function Text({ children, csx, baseProps }) {
  return (
    <Typography sx={{ ...TextStyleSx, ...csx }} {...baseProps}>
      {children}
    </Typography>
  )
}
