import React from "react"
import { Controller } from "react-hook-form"

import { LinkButton, Select } from "@ioxio-priv/dataspace-ui"

import ROUTES from "../constants/routes"

export default function FormSelect({
  name,
  label,
  form,
  options,
  showAddGroupLink,
  selectSx = {},
  baseProps = {},
  required,
  placeholder,
  tooltipText,
  children,
}) {
  let err = ""
  if (Object.prototype.hasOwnProperty.call(form.formState.errors, name)) {
    err = form.formState.errors[name].message
  }

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={""}
      render={({ field }) => (
        <Select
          label={label}
          name={name}
          handleChange={field.onChange}
          baseProps={{ sx: selectSx, ...field, ...baseProps }}
          options={options}
          addItemButton={
            showAddGroupLink && (
              <LinkButton href={ROUTES.GROUP_LIST} color={"success"}>
                + Add a new group
              </LinkButton>
            )
          }
          error={err}
          value={field.value}
          required={required}
          placeholder={placeholder}
          tooltipText={tooltipText}
        >
          {children && <>{children}</>}
        </Select>
      )}
    />
  )
}
