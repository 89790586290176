import * as React from "react"
import { Redirect, Route } from "react-router-dom"

import ROUTES from "@/constants/routes.json"
import FullPageSpinner from "@/containers/FullPageSpinner"
import StartLogout from "@/containers/StartLogout"
import useUserData from "@/hooks/useUserData"

function generateLoginRedirect(path, search) {
  const { LOGIN } = ROUTES
  if (search?.split("=")[1]) {
    return `${LOGIN}?after_login=${path}&search=${search?.split("=")[1]}`
  }
  return `${LOGIN}?after_login=${path}`
}

export default function ProtectedRoute({
  component: Component,
  componentParams,
  path,
  exact = true,
}) {
  const { meRequestData, loading } = useUserData()

  if (loading) {
    return <FullPageSpinner />
  } else {
    return (
      <Route
        exact={exact}
        path={path}
        render={(props) => {
          if (meRequestData) {
            if (meRequestData.termsAccepted) {
              return (
                <Component userData={meRequestData} {...props} {...componentParams} />
              )
            } else {
              return <StartLogout />
            }
          } else {
            return (
              <Redirect
                to={generateLoginRedirect(
                  props.location.pathname,
                  props.location.search
                )}
              />
            )
          }
        }}
      />
    )
  }
}
