class API {
  baseUrl = "/api"
  defaultErrorMessage =
    "Please try again later. If the issue does not resolve itself, please contact support."
  networkErrorMessage = "Temporary network issue occurred. Please try again later."
  headers = {
    "Content-Type": "application/json",
  }

  async responseWrapper(r) {
    try {
      if (r.ok) {
        if (r.status === 204) {
          return { ok: r.ok, status: r.status }
        }
        let data = await r.json()
        return { ok: r.ok, data, status: r.status }
      } else {
        return {
          ok: r.ok,
          error: (await r.json()).detail || r.text(),
          status: r.status,
        }
      }
    } catch (e) {
      return { ok: false, error: this.defaultErrorMessage }
    }
  }

  async makeRequest(url, options) {
    try {
      const response = await fetch(url, options)
      return await this.responseWrapper(response)
    } catch (e) {
      console.error(e)
      return { ok: false, error: this.networkErrorMessage }
    }
  }
}

export default API
