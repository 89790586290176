import API from "./API"

class DataSourceGroupAPI extends API {
  async addGroup(data) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async deleteGroup(group) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${group}`, {
      method: "DELETE",
      headers: this.headers,
    })
  }

  async updateGroup(data) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${data.group}`, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async getMyGroups() {
    return await this.makeRequest(`${this.baseUrl}/v2/groups`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async getGroup(group) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${group}`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async getGroupMembers(group) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${group}/members`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async inviteToGroup(group, data) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${group}/invites`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async removeFromGroup(group, sub) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${group}/members/${sub}`, {
      method: "DELETE",
      headers: this.headers,
    })
  }

  async updateGroupMemberRole(group, sub, data) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${group}/members/${sub}`, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async getGroupInvitations(group) {
    return await this.makeRequest(`${this.baseUrl}/v2/groups/${group}/invites`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async cancelGroupInvitation(group, inviteId) {
    return await this.makeRequest(
      `${this.baseUrl}/v2/groups/${group}/invites/${inviteId}`,
      {
        method: "DELETE",
        headers: this.headers,
      }
    )
  }
}

const instance = new DataSourceGroupAPI()

export default instance
