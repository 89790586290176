import React, { useState } from "react"
import { useAsync } from "react-async-hook"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { EditAppForm, ThirdPartyEditAppForm } from "@/components/AppForm"
import SecretInput from "@/components/SecretInput"
import Spinner from "@/components/Spinner"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import ApplicationWrapper from "@/containers/ApplicationWrapper"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import AppsAPI from "@/services/appsAPI"
import { config } from "@/settings"

const spinnerSx = {
  m: 4,
}

export default function ApplicationDetailsScreen({ history }) {
  // render navbar and footer
  useLayoutOpts(true, true)
  const urlParams = useParams()
  const clientId = urlParams.clientId
  const [app, setApp] = useState({})
  const [notFound, setNotFound] = useState(false)

  const appReq = useAsync(async () => {
    const { ok, data } = await AppsAPI.getApplication(clientId)
    if (ok) {
      setApp(data)
    } else {
      setNotFound(true)
    }
  }, [clientId])

  async function refreshSecret(secret) {
    const { ok, data } = await AppsAPI.refreshSecrets(clientId, secret)
    if (ok) {
      const newSecrets = app.clientSecrets.map((oldSecret) =>
        oldSecret === secret ? data.clientSecret : oldSecret
      )
      setApp({ ...app, ...{ clientSecrets: newSecrets } })
      await toast.success("New client secret has been generated")
    }
    return { ok }
  }

  async function removeApplication() {
    const { ok } = await AppsAPI.removeApplication(clientId)
    if (ok) {
      await toast.success(`Application "${app.name}" has been deleted`)
      await history.push(ROUTES.APPLICATIONS)
    }
    return { ok }
  }

  async function updateApplication(payload, handleErrors) {
    const { ok, error } = await AppsAPI.updateApplication(clientId, payload)
    if (ok) {
      await appReq.execute()
      toast.success("Application has been updated")
    } else {
      handleErrors(error)
    }
    return { ok }
  }

  const refreshDescription = (
    <>
      Are you sure you wish to refresh the client secret of <b>{app.appName}</b>?
      Refreshing will permanently remove the current secret, and you will not be able to
      restore it.
    </>
  )

  return (
    <ApplicationWrapper
      breadCrumbs={app ? app.name : clientId}
      title={!appReq.loading && `Editing ${app.name ? app.name : ""}`}
      editPage={true}
      meta={labels.meta.editApplications}
      belowHeader={
        notFound && (
          <div>The application doesn't exist or you don't have an access to it</div>
        )
      }
    >
      {appReq.loading && <Spinner csx={spinnerSx} />}
      {!appReq.loading && app.name && !config.externalAppManagement && (
        <EditAppForm
          app={app}
          asyncOnSubmit={updateApplication}
          onSecretRefresh={refreshSecret}
          onAppRemove={removeApplication}
        >
          {app.clientSecrets.map((secret) => (
            <SecretInput
              key={secret}
              secret={secret}
              name={`Client secret`}
              asyncOnRefresh={refreshSecret}
              modalDescription={refreshDescription}
            />
          ))}
        </EditAppForm>
      )}
      {!appReq.loading && app.name && config.externalAppManagement && (
        <ThirdPartyEditAppForm
          app={app}
          asyncOnSubmit={updateApplication}
          onAppRemove={removeApplication}
        />
      )}
    </ApplicationWrapper>
  )
}
