import React from "react"
import { Controller } from "react-hook-form"

import { RadioInput } from "@ioxio-priv/dataspace-ui"

export default function FormRadioGroup({ name, form, options = [] }) {
  return (
    <Controller
      rules={{ required: true }}
      control={form.control}
      defaultValue={false}
      name={name}
      render={({ field }) => <RadioInput options={options} baseProps={{ ...field }} />}
    />
  )
}
