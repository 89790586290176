import React from "react"
import { toast } from "react-toastify"

export function toastError(title, err) {
  toast.error(
    <div>
      <div>{title}</div>
      <br />
      {err && <div>{err}</div>}
    </div>
  )
}
