import API from "./API"

class ConsentAPI extends API {
  async acceptTerms() {
    return await this.makeRequest(`${this.baseUrl}/auth/accept-terms`, {
      method: "POST",
      headers: this.headers,
    })
  }
}

const instance = new ConsentAPI()

export default instance
